import { createRoot } from 'react-dom/client';
import React from 'react';
import { useState } from 'react';
import { Dialog } from '@headlessui/react'
import Footer from './components/Footer'
import Story from './components/Story'
import Event from './components/Event'
import './index.css'
import './landing.css'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';


let { NODE_ENV } = process.env;

var faro = initializeFaro({
  url: '/t',
  app: {
    name: 'hackonguns',
    version: '1.0.0',
    environment: NODE_ENV
  },
  instrumentations: [
    ...getWebInstrumentations(),
    new TracingInstrumentation(),
  ],
});


const armedUrl = new URL(
  './assets/initial-with-logo-v3.png',
  import.meta.url
);

const trippedUrl = new URL(
  './assets/tripped-with-logo-v3.png',
  import.meta.url
);

const goalUrl = new URL(
  './assets/goal-with-logo-final-nocolor-nolabel-v6.png',
  import.meta.url
);
const goalBrandedUrl = new URL(
  './assets/goal-with-logo-final-v5.png',
  import.meta.url
);

const goalBrandedNoColorUrl = new URL(
  './assets/goal-with-logo-final-v2.png',
  import.meta.url
);
const whiteSymbolUrl = new URL(
  './assets/ch-logo-white.png',
  import.meta.url
);

const eventUrl = new URL(
  './assets/shooting-for-change-hackathon-flyer-v2.jpeg',
  import.meta.url
);
const container = document.getElementById('app');
const root = createRoot(container);

const heroDescription = "A research project to create a system to detect firearms when they get too close to a school.";

const navigation = [
  { name: 'About the Solution', href: '#learnmore', targetBlank: false, additionalClassNames: "hackonguns-more" },
  { name: 'Hackathon Resources', href: 'https://docs.hackonguns.com/start-here/project-description', targetBlank: true, additionalClassNames: "hackonguns-resources" },
]

const eventOptions = [
  { name: "Date & Time: November 10th, 2023", description: ""},
  { name: "The Wond'ry - 2414 Highland Ave, Unit 102, Nashville, TN", description: ""},
]

const armedOptions = [
  { name: "Plastics, Fabrics & Alloys:", description: "materials we can bind to the structure of the firearm."},
  { name: "Ointments & Aerosols:", description: "substances we can spray and rub on the gun"},
  { name: "Loud & Bright", description: "The right marker will make it trivial for a computer system to 'see' it."},
  { name: "Mechanical", description: "Are there physical after-market alternations we can make?"},
  { name: "Burden Imposing", description: "Is there a marker that can impose a physiological or psychological burden on the user?"},
]

const trippedOptions = [
  { name: "Visual", description: "Are there visual alarms we can design (e.g., blinking lights)"},
  { name: "Analog", description: ""},
  { name: "Digital", description: "How can we leverage existing Wifi and Cellar networks?"},
  { name: "Auditory", description: "Could we use an alarm or message help inform the presence of a firearm?"},
]
const goalOptions = [
  { name: "retrofit guns in circulation", description: "How can increase the chances that a gun is marked?"},
  { name: "Build an Armory", description: "Are there facilities can we repurpose or build to host retrofitting and buy-back programs?"},
  { name: "Gun buy-back programs", description: "Can we increase the percentage of retrofitted guns by reducing the total guns in circulation?"},
  { name: "Template Legislation", description: "Is there legislation we can  devise to ensure that manufactured and imported firearms are marked?"},
]

function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
     <div className="bg-gray-900">
          <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
              <div className="flex lg:flex-1">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Civic Hacker</span>
                  <img
                    className="h-8 w-auto"
                    src={whiteSymbolUrl}
                    alt="Civic Hacker logo"
                  />
                </a>
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href}
                    target={item.targetBlank ? '_blank': '_self'}
                    className={`${item.additionalClassNames} text-sm font-semibold leading-6 text-white`}>
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                <div className="flex items-center justify-between">
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Civic Hacker</span>
                    <img
                      className="h-8 w-auto"
                      src={whiteSymbolUrl}
                      alt="Civic Hacker logo"
                    />
                  </a>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/25">
                    <div className="space-y-2 py-6">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          target={item.targetBlank ? '_blank': '_self'}
                          className={`${item.additionalClassNames} -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800`}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>

          <div className="relative isolate pt-14">
            <div
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
            <div className="pb-4 pt-8 sm:pb-2 sm:pt-12 lg:pb-8">
              <div className="mx-auto max-w-7xl md:max-w-2xl lg:max-w-3xl px-8 lg:px-12">
                <div className="mx-auto max-w-2xl text-center">
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl flex flex-col space-y-1">
                    <span>Hack on Guns&trade;</span><span className="text-3xl">for Public Good</span>
                  </h1>
                  <p className="mt-6 text-3xl leading-8 text-white">
                    {heroDescription}
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6 mb-6">
                    <a
                      href="/signup"
                      className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                    >
                      Subscribe for Updates
                    </a>
                  </div>
                </div>
                <img
                  src={goalBrandedUrl}
                  alt="An image showing Civic Hacker's prototype of the Wide-area detection system."
                  width={2432}
                  height={1442}
                  className="mt-2.5 rounded-md bg-white/5 ring-1 ring-white/10 sm:mt-4"
                />
              </div>
            </div>
            <div
              className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>
        </div>
        {/*
        <div className="flex h-[755px] md:h-[450px] lg:h-96" id="top-of-funnel">
            <iframe src="https://app.civichacker.com/campaign/signup/hackonguns-com/" sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms allow-popups" loading="eager" className="flex-1" scrolling="no"></iframe>
        </div>
        */}
        <div className="relative max-w-5xl text-base leading-7 text-gray-700 lg:max-w-5xl w-full mx-auto px-4 lg:px-0 flex flex-col">
        {/*
        <Event
            mainImage={eventUrl}
            contextHeader="hackathon event"
            contextColor="text-red"
            useTexture={false}
            smallImage={true}
            largeHeader="Shooting for Change"
            summary="Armed with data, students, technologists, professors, volunteers, law makers, and parents will come together to design markersand template legislation to improve school safety."
            options={eventOptions}
        >
            <a href="https://www.meetup.com/blacks-in-technology-nashville/events/297073834/" target="_blank" type="button" className="hackonguns-rvsp mt-6 mx-auto text-center w-72 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">RVSP here</a>
        </Event>
        */}
      <h2 className="hidden mt-8 text-2xl font-bold tracking-tight text-gray-900 text-center">We Need More Non-partisan Solutions</h2>
            <p className="hidden mt-2">
                We face obstacles in improving school safety due to extreme partisanship from both sides. But together, we can work towards finding solutions that are fair and inclusive for all members of our community. Your input and support will help us in our ongoing efforts to research non-partisan and anti-oppressive strategies to tackle these issues.
            </p>
            <a href="https://buy.stripe.com/9AQ16Xbas6c0f5ufZ0" target="_blank" type="button" className="hidden hackonguns-plink mt-6 mx-auto text-center w-72 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">Yes, Let's work together</a>
        </div>
        <Story
            mainImage={armedUrl}
            contextHeader="system armed"
            contextColor="text-blue"
            largeHeader="Wide-area detection"
            summary="Wide-area Detection establishes a perimeter around schools, where we want to detect when an unconsealed firearm gets too close. When a marked firearm enters the perimeter, notifications are sent to authorities and staff both inside & outside the perimeter."
            depth="Markers are designed to make an unconceled firearm easier to detect through some automated means. We do not care about the identify the firearm user, just whether or not the firearm got too close to the structure. When brainstorming possible markers consider any of these sorts of markers:"
            options={armedOptions}
            internalAnchor="learnmore"
        />
        <Story
            mainImage={trippedUrl}
            contextHeader="system tripped"
            contextColor="text-red"
            largeHeader="Notification System"
            summary="When a marked firearm enters the perimeter, notifications are sent to authorities and staff both inside & outside the perimeter."
            depth="There are a number of readily-available technologies well-execercised emergency response techniques. To build a system that can confirms the firearm's presence and send notifications to can leverage analog, digital, and cloud-native protocols such as MQTT, TCP/IP, and UDP for communication over large distances. Communication within the perimeter can leverage digital and analog communication channels such as auditory, visual, cellular, radio, and wifi."
            moreoverHeader="Exploring markers for firearms"
            moreoverBody="The choice of markers for firearms will drive the deployment of portions of the internal perimeter communication of WAGS. This document assumes that markers are strictly designed to detect perimeter breaches and not necessarily to identify the firearm user or triangulate the firearm's location relative to the structure. As a part of the effort to design a marker, the implementer must remember that it must be possible to retrofit existing firearms with the marker. Identifying and testing various markers may be most feasible when Private-Public partnerships and 'Wisdom of the Crowd' techniques such as Hackathons are used. Please consider all sorts of marker compositions and applications such as electronic, flourescent, even chemical markers sush as some CORMs."
            options={trippedOptions}
        />
        <Story
            mainImage={goalUrl}
            contextHeader="End Goal"
            contextColor="text-purple"
            largeHeader="Community-driven Retrofitting"
            summary="The goal a retrofitting program is to maximize the number of marked guns in circulation."
            depth="The community has the critical role of retrofitting firearms already in circulation. Similar to the 'gun buy-back program' model, a community retrofitting program would provide incentives to individuals who participate. Furthermore, a 'no-questions asked' policy would help ensure confidence that participation does not lead to persecution."
            options={goalOptions}
        />
        <div className="relative max-w-3xl text-base leading-7 text-gray-700 lg:max-w-4xl w-full mx-auto px-6 lg:px-0 flex flex-col">
            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-base text-gray-900 uppercase">This is the bottom line</span>
                </div>
            </div>
            {/*
            <div className="flex h-[755px] md:h-[450px] lg:h-96" id="top-of-funnel-footer">
                <iframe src="https://app.civichacker.com/campaign/signup/hackonguns-com/" sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms allow-popups" loading="eager" className="flex-1" scrolling="no"></iframe>
            </div>
            */}
        </div>
        <Footer />
      </>
  )
}

root.render(<Landing />);
